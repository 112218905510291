<template>
  <footer id="footer" class="footer-footer footerContainer">
    <div class="footer-container"></div>
    <div class="footer-separator"></div>
    <div class="footer-container1">
      <div class="footer-icon-group">
        <footer class="footer-footer1">
          <div class="footer-container2">
            <div class="footer-logo">
              <span class="footer-text">{{ text1 }}</span>
            </div>
            <div class="footer-links-container">
              <div class="footer-container3"></div>
              <div class="footer-container4">
                <div class="footer-contact">
                  <span class="footer-text1">{{ text2 }}</span>
                  <a
                    href="mailto:contact@parasrenewables.com?subject="
                    class="footer-link"
                  >
                    {{ text3 }}
                  </a>
                  <span>
                    <a href="tel:+917701807087" class="footer-link1">
                      +91 77018 07087
                    </a>
                    <a href="tel:+917701807087"><br class="footer-text3" /></a>
                    <a href="tel:+919540717366" class="footer-link3">
                      +91 95407 17366
                    </a>
                    <br />
                  </span>
                  <span class="footer-text5">{{ text6 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-separator1"></div>
          <span class="footer-text6">{{ text }}</span>
          <span class="footer-text7">{{ text4 }}</span>
        </footer>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    text1: {
      type: String,
      default: 'Paras Engineers Works',
    },
    text: {
      type: String,
      default: '© 2024 Paras Engineers Works',
    },
    text2: {
      type: String,
      default: 'Contact Us',
    },
    text3: {
      type: String,
      default: 'contact@parasengineersworks.com',
    },
    text4: {
      type: String,
      default: 'All Rights Reserved',
    },
    text6: {
      type: String,
      default:
        'Plot No K-1/15, KH No. 1221, Block K, Prem Nagar II, 70 Feet Road, Near Amrawati Polyclinic, Nangloi, Delhi - 110041',
    },
  },
}
</script>

<style scoped>
.footer-footer {
  position: relative;
}
.footer-container {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-icon-group {
  width: 1119px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-footer1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  font-size: 24px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: bold;
}
.footer-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container4 {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-contact {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text1 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link1 {
  text-decoration: underline;
}
.footer-text3 {
  text-decoration: underline;
}
.footer-link3 {
  text-decoration: underline;
}
.footer-text5 {
  overflow-wrap: break-all;
}
.footer-separator1 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.footer-text6 {
  align-self: center;
}
.footer-text7 {
  align-self: center;
}
@media(max-width: 991px) {
  .footer-footer1 {
    flex-direction: column;
  }
  .footer-container3 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-container2 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-logo {
    align-items: center;
  }
  .footer-text {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .footer-container3 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .footer-text5 {
    margin-top: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-icon-group {
    width: auto;
  }
  .footer-footer1 {
    width: 100%;
    padding: var(--dl-space-space-unit);
    max-width: auto;
    flex-direction: row;
  }
  .footer-container2 {
    align-items: center;
    flex-direction: column;
  }
  .footer-text {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-links-container {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .footer-container3 {
    margin-right: 0px;
  }
  .footer-container4 {
    width: auto;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .footer-contact {
    flex: 0;
    width: auto;
    position: relative;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .footer-text5 {
    width: 100%;
  }
  .footer-text6 {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .footer-text7 {
    width: 100%;
    height: auto;
    text-align: center;
  }
}
</style>
