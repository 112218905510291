<template>
  <div class="products-container">
    <div class="products-gallery">
      <h1 class="products-heading"> </h1>
      <div class="products-container1">
        <gallery-card3
          title="Polyurethane Molds"
          imageSrc="/external/picsart-08-21-01-47-17-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName"
        ></gallery-card3>
        <gallery-card3
          title="Bullet Seat PU Mould"
          imageSrc="/external/bullet-seat-pu-mould-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName1"
        ></gallery-card3>
        <gallery-card3
          title="Boxing Gloves Mould"
          imageSrc="/external/gloves-mould-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName3"
        ></gallery-card3>
        <gallery-card3
          title="Two Wheeler Seat Aluminum Mold"
          imageSrc="/external/two-wheeler-seat-aluminium-mold-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName2"
        ></gallery-card3>
        <gallery-card3
          title="Mold For Smiley Balls"
          imageSrc="/external/picsart-08-21-01-54-42-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName4"
        ></gallery-card3>
        <gallery-card3
          title="Sofa Cushion Mould"
          imageSrc="/external/sofa-cushion-mould-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName5"
        ></gallery-card3>
        <gallery-card3
          title="Tractor Seat Vacuum Type Mould"
          imageSrc="/external/tractor-seat-vacuum-type-mould-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName6"
        ></gallery-card3>
        <gallery-card3
          title="Cycle Seat FRP Mould"
          imageSrc="/external/cycle-seat-mould-500x500-1500h.webp"
          subtitle=" "
          rootClassName="rootClassName7"
        ></gallery-card3>
      </div>
    </div>
    <app-header2 rootClassName="header2-root-class-name"></app-header2>
    <app-footer></app-footer>
  </div>
</template>

<script>
import GalleryCard3 from '../components/gallery-card3'
import AppHeader2 from '../components/header2'
import AppFooter from '../components/footer'

export default {
  name: 'Products',
  props: {},
  components: {
    GalleryCard3,
    AppHeader2,
    AppFooter,
  },
  metaInfo: {
    title: 'Products - Paras Engineers Works',
    meta: [
      {
        name: 'description',
        content:
          'Involved in the area of manufacturing a broad plethora of Polyurethane Mold, Furniture Seat Mould, Boxing Gloves Mould and much more.',
      },
      {
        property: 'og:title',
        content: 'Products - Paras Engineers Works',
      },
      {
        property: 'og:description',
        content:
          'Involved in the area of manufacturing a broad plethora of Polyurethane Mold, Furniture Seat Mould, Boxing Gloves Mould and much more.',
      },
    ],
  },
}
</script>

<style scoped>
.products-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.products-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.products-heading {
  font-size: 3rem;
  text-align: center;
}
.products-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .products-container1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .products-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .products-container1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 479px) {
  .products-gallery {
    padding: var(--dl-space-space-unit);
  }
  .products-container1 {
    grid-template-columns: 1fr;
  }
}
</style>
