<template>
  <div class="home-container">
    <app-header2></app-header2>
    <div class="home-hero">
      <div class="heroContainer home-hero1">
        <div class="home-container1">
          <h1 class="home-hero-heading heading1">
            Manufacturing Excellence in Polyurethane Molds
          </h1>
          <span class="home-hero-sub-heading bodyLarge">
            <span>
              <span>
                <span>Creating Innovative Solutions for Your Molding Needs</span>
                <span><span v-html="raw2hz5"></span></span>
              </span>
              <span>
                <span><span v-html="rawwe9v"></span></span>
                <span><span v-html="rawdak1"></span></span>
              </span>
            </span>
            <span>
              <span>
                <span><span v-html="rawf1xu"></span></span>
                <span><span v-html="rawy26n"></span></span>
              </span>
              <span>
                <span><span v-html="rawzzri"></span></span>
                <span><span v-html="rawrlmm"></span></span>
              </span>
            </span>
          </span>
          <div class="home-btn-group">
            <router-link to="/products" class="home-hero-button1 buttonFilled">
              Explore Our Products
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="home-features">
      <div class="featuresContainer">
        <div class="home-features1">
          <div class="home-container2">
            <span class="overline">
              <span>features</span>
              <br />
            </span>
            <h2 class="home-features-heading heading2">
              Superior Features for Exceptional Molds
            </h2>
            <span class="home-features-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Discover the key features that set our molds apart from the
                    rest.
                  </span>
                  <span><span v-html="rawysrt"></span></span>
                </span>
                <span>
                  <span><span v-html="raw46pi"></span></span>
                  <span><span v-html="rawyvlp"></span></span>
                </span>
              </span>
              <span>
                <span>
                  <span><span v-html="raw9vwy"></span></span>
                  <span><span v-html="rawz5px"></span></span>
                </span>
                <span>
                  <span><span v-html="rawcdgq"></span></span>
                  <span><span v-html="rawfn70"></span></span>
                </span>
              </span>
            </span>
          </div>
          <div class="home-container3">
            <feature-card
              heading="Wide Range of Mold Options"
              subHeading="Choose from a diverse selection of polyurethane mold, furniture seat mold, boxing gloves mold, and more."
            ></feature-card>
            <feature-card
              heading="High-Quality Materials"
              subHeading="Our professionals use only top-notch basic materials in the manufacturing process."
            ></feature-card>
            <feature-card
              heading="State-of-the-Art Tools and Machinery"
              subHeading="We employ ultra-modern tools and machinery to ensure the highest level of precision and quality in our molds."
            ></feature-card>
            <feature-card
              heading="Expert Development Process"
              subHeading="Our professionals follow a meticulous development process to deliver exceptional molds for various applications."
            ></feature-card>
          </div>
        </div>
      </div>
    </div>
    <div class="home-banner">
      <div class="bannerContainer home-banner1">
        <h1 class="home-banner-heading heading2">
          Wide Range of High-Quality Molds
        </h1>
        <span class="home-banner-sub-heading bodySmall">
          <span>
            <span>
              <span>
                At our company, we specialize in manufacturing a diverse range of
                polyurethane molds, furniture seat molds, boxing gloves molds, and
                much more. Our team of professionals utilizes top-notch materials
                and state-of-the-art tools and machinery to ensure the highest
                quality in our products.
              </span>
              <span><span v-html="rawg1t1"></span></span>
            </span>
            <span>
              <span><span v-html="rawpdpr"></span></span>
              <span><span v-html="rawt5r5"></span></span>
            </span>
          </span>
          <span>
            <span>
              <span><span v-html="rawqu8l"></span></span>
              <span><span v-html="raw29l6"></span></span>
            </span>
            <span>
              <span><span v-html="rawfxdp"></span></span>
              <span><span v-html="raw7on8"></span></span>
            </span>
          </span>
        </span>
        <router-link to="/products" class="home-banner-button buttonFilled">
          Learn More
        </router-link>
      </div>
    </div>
    <div class="home-faq">
      <div class="faqContainer">
        <div class="home-faq1">
          <div class="home-container4">
            <span class="overline">
              <span>FAQ</span>
              <br />
            </span>
            <h2 class="home-text48 heading2">Common questions</h2>
            <span class="home-text49 bodyLarge">
              <span>Here are some of the most common questions that we get.</span>
              <br />
            </span>
          </div>
          <div class="home-container5">
            <app-question1
              answer="We manufacture a broad plethora of molds including Polyurethane Mold, Furniture Seat Mould, Boxing Gloves Mould, and much more."
              question="What types of molds do you manufacture?"
            ></app-question1>
            <app-question1
              answer="Our professionals use only top-notch basic materials."
              question="What materials do your professionals use in the development process?"
            ></app-question1>
            <app-question1
              answer="We utilize ultra-modern tools and machinery in our manufacturing process."
              question="What tools and machinery do you use in the manufacturing process?"
            ></app-question1>
            <app-question1
              answer="Yes, we assure the quality of our products by using top-notch materials and modern manufacturing techniques."
              question="Do you assure the quality of your products?"
            ></app-question1>
            <app-question1
              answer="Yes, we accept custom mold design requests. Please contact us for more information."
              question="Can I request a custom mold design?"
            ></app-question1>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader2 from '../components/header2'
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  props: {},
  components: {
    AppHeader2,
    FeatureCard,
    AppQuestion1,
    AppFooter,
  },
  data() {
    return {
      raw2hz5: ' ',
      rawwe9v: ' ',
      rawdak1: ' ',
      rawf1xu: ' ',
      rawy26n: ' ',
      rawzzri: ' ',
      rawrlmm: ' ',
      rawysrt: ' ',
      raw46pi: ' ',
      rawyvlp: ' ',
      raw9vwy: ' ',
      rawz5px: ' ',
      rawcdgq: ' ',
      rawfn70: ' ',
      rawg1t1: ' ',
      rawpdpr: ' ',
      rawt5r5: ' ',
      rawqu8l: ' ',
      raw29l6: ' ',
      rawfxdp: ' ',
      raw7on8: ' ',
    }
  },
  metaInfo: {
    title: 'Paras Engineers Works',
    meta: [
      {
        name: 'description',
        content:
          'Involved in the area of manufacturing a broad plethora of Polyurethane Mold, Furniture Seat Mould, Boxing Gloves Mould and much more.',
      },
      {
        property: 'og:title',
        content: 'Paras Engineers Works',
      },
      {
        property: 'og:description',
        content:
          'Involved in the area of manufacturing a broad plethora of Polyurethane Mold, Furniture Seat Mould, Boxing Gloves Mould and much more.',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.home-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  max-width: 800px;
  text-align: center;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-hero-button1 {
  text-decoration: none;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner-heading {
  text-align: center;
}
.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-banner-button {
  text-decoration: none;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container4 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text48 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text49 {
  text-align: left;
}
.home-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-banner-sub-heading {
    max-width: 100%;
  }
  .home-text49 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container3 {
    grid-template-columns: 1fr;
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container4 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text49 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
</style>
